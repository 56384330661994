import React from 'react'
import { useSelector } from 'react-redux'
import { convertIdProcessPointsToLine } from '../../../helpers/convertIdProcessPointsToLine';

export const CanvasMoreInfoMobileComponent = () => {
    const process = useSelector(state => state.selectProcess.value)
    const idAux = process.id ? convertIdProcessPointsToLine(process.id) + 'offcanvasmoreinfo' : 'offcanvasmoreinfo';
    console.log(idAux);
    if (idAux === 'offcanvasmoreinfo') {
        return (
            <>
                <div className="offcanvas offcanvas-start" tabIndex="-1" id={`${idAux}`} aria-labelledby="offcanvasmoreinfo" key={idAux}>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title fw-bold" id="offcanvasmoreinfo">Ver más info</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <hr />
                </div>
            </>
        )
    } else {
        return (
            <div className="offcanvas offcanvas-start" tabIndex="-1" id={`${idAux}`} aria-labelledby="offcanvasmoreinfo"  key={idAux}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title fw-bold" id="offcanvasmoreinfo">Ver más info</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <hr />
                <div className="offcanvas-body">
                    <div className="card shadow-box without-b-r">
                        <div className="card-body">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='fw-semibold text-muted'>ID</p>
                                    </div>
                                    <div className="col">
                                        <p className='fw-semibold'>{process.id ? process.id : ''}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='fw-semibold text-muted'>Tipo de proceso</p>
                                    </div>
                                    <div className="col">
                                        <p className='fw-semibold'>{process.id ? process.typeProcess : ''}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='fw-semibold text-muted'>Sistema</p>
                                    </div>
                                    <div className="col">
                                        <p className='fw-semibold'>{process.id ? process.system : ''}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='fw-semibold text-muted'>Proveedor</p>
                                    </div>
                                    <div className="col">
                                        <p className='fw-semibold'>{process.id ? process.data.provider : ''}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='fw-semibold text-muted'>Personal crítico</p>
                                    </div>
                                    <div className="col">
                                        <p className='fw-semibold'>{process.id ? process.data.criticalPersonal[0] : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            {
                                [1, 2, 3, 4].map((item, index) => {
                                    return <>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className='fw-semibold text-muted mb-0'>Nivel {item} proceso</p>
                                                <p className='mt-0'>example of name process level</p>
                                            </div>
                                        </div>
                                    </>
                                })
                            }
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Objetivo / Breve descripción</p>
                                    <p className='mt-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, debitis atque necessitatibus neque quas dolores labore vitae culpa lau...</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pb-3">
                                    <p className='fw-semibold text-muted mb-0'>Link diagrama</p>
                                    <a href="https://falabella.sharepoint.com/sites" className='text-green'>https://falabella.sharepoint.com/sites</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Asociado a producto o servicio</p>
                                    <p className='mt-0'>Si</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Especificar a producto o servicio asociado</p>
                                    <p className='mt-0'>Todos los productos BF y CMR</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Gerencia dueña</p>
                                    <p className='mt-0'>Planificación y Control financiero</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Gerencia dueño</p>
                                    <p className='mt-0'>Carolina Lavia</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Dueño del proceso</p>
                                    <p className='mt-0'>Solange Espinoza</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Usuario clave</p>
                                    <p className='mt-0'>Marcelo Risso</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Gerencia 2a. línea</p>
                                    <p className='mt-0'>Administración y compras</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Gerente 2a. línea</p>
                                    <p className='mt-0'>Solange Espinoza</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Rol diseño</p>
                                    <p className='mt-0'>Gerente de Administración y comrpas</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className='fw-semibold text-muted mb-0'>Rol usuario clave</p>
                                    <p className='mt-0'>Jefe de Servicios Generales</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

import { createSlice } from '@reduxjs/toolkit';

export const selectProcessSlice = createSlice({
    name: 'selectProcess',
    initialState: {
        value: {
        }
    },
    reducers: {
        setProcess: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setProcess } = selectProcessSlice.actions;

export default selectProcessSlice.reducer;

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { EvaluacionBiaPage } from '../pages/EvaluacionBiaPage'
import { MaintainerPage } from '../pages/MaintainerPage'
import { ProcessPage } from '../pages/ProcessPage'
import { ProcessEvaluationPage } from '../pages/ProcessEvaluationPage'
import { NavbarComponent } from '../components/navbar/NavbarComponent'
import { OffcanvasCreateProcess } from '../components/offcanvas/createprocess/OffcanvasCreateProcess'
export const Rutas = () => {
  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Navigate to="proceso" />} />
        <Route path="/proceso">
          <Route path="" element={<ProcessPage />} />
          <Route path="evaluacion/:id" element={<ProcessEvaluationPage />} />
        </Route>
        <Route path="/evaluacion" element={<EvaluacionBiaPage />} />
        <Route path="/mantenedor" element={<MaintainerPage />} />
      </Routes>
      <OffcanvasCreateProcess />
    </>
  )
}

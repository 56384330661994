import { createSlice } from '@reduxjs/toolkit';
//TODO: this obj its only reference, so if you change it, it will change in the store
let obj = {
    id: 'SO.PCF.1',
    typeProcess:'Soporte',
    update: "2021.05.01",
    process:'Abastecimiento y equipamiento sucursales y casas matriz',
    system: '---',
    level: 1,
    state: 1,
    financial: 2,
    reputational: 7,
    regulatory:6,
    priority: 'Vital',
    impact: 0,
    result:0,
    data:{
        system: 'SAP / Telemarketing',
        provider: 'IMET',
        criticalPersonal:['Carolina Carrasco Pinilla', 'Camila Bermudéz Soto'],
        backup:['Rodrigo Guerrero Venegas', 'Alejandro Guerrero Venegas' ],
        rto:{
            state: 1,
            minutes: 45
        },
        mtpd:{
            state: 1,
            minutes: 24
        }
    }
}

export const listProcessSlice = createSlice({
    name: 'listProcess',
    initialState: {
        value: [
            {
                id: 'SO.PCF.1',
                typeProcess:'Soporte',
                update: "2021.05.01",
                process:'Abastecimiento y equipamiento sucursales y casas matriz',
                system: '---',
                level: 1,
                state: 1,
                financial: 2,
                reputational: 7,
                regulatory:6,
                priority: 'Vital',
                impact: 0,
                result:0,
                data:{
                    system: 'SAP / Telemarketing',
                    provider: 'IMET',
                    criticalPersonal:['Carolina Carrasco Pinilla', 'Camila Bermudéz Soto'],
                    backup:['Rodrigo Guerrero Venegas', 'Alejandro Guerrero Venegas' ],
                    rto:{
                        state: 1,
                        minutes: 45
                    },
                    mtpd:{
                        state: 1,
                        minutes: 24
                    }
                }
            },
            {
                id: 'SO.PCF.2',
                typeProcess:'Ventas',
                update: "2021.05.01",
                process:'Abastecimiento y equipamiento sucursales',
                system: '---',
                level: 1,
                state: 0,
                financial: 2,
                reputational: 7,
                regulatory:6,
                priority: 'Vital',
                impact: 0,
                result:0,
                data:{
                    system: 'SAP / Telemarketing',
                    provider: 'IMET',
                    criticalPersonal:['Carolina Carrasco Pinilla', 'Camila Bermudéz Soto'],
                    backup:['Rodrigo Guerrero Venegas', 'Alejandro Guerrero Venegas' ],
                    rto:{
                        state: 1,
                        minutes: 45
                    },
                    mtpd:{
                        state: 1,
                        minutes: 24
                    }
                }
            }
        ],
    },
    reducers: {
        setListProcess: (state, action) => {
            state.value = action.payload;
        },
        getProcessById: (state, action) => {
            return state.value.filter(process => process.id === action.payload);
        }
    },
})

export const { setListProcess, getProcessById } = listProcessSlice.actions;

export default listProcessSlice.reducer;
import React from 'react'
import { Link } from 'react-router-dom'
import { OffcanvasProcessBiaComponent } from '../components/offcanvas/processbia/OffcanvasComponent'
import { TableProcessComponent } from "../components/TableProcess/TableProcessComponent"
import { TableProcessMobileComponent } from '../components/TableProcess/mobile/TableProcessMobileComponent'

import './../styles/processpagestyle.css'

export const ProcessPage = () => {
  const title = 'Procesos BIA'
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-lg-4 col-sm-4">
            <h3 className='fw-semibold'>{title}</h3>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12 col-sm-12">
            <div className="card bg-light-green">
              <div className="row mt-3 ms-3 me-3 mb-3 item-small-filter">
                <div className="col-9">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-2">
                  <span className='btn btn-light' type='button' data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${"filterprocess"}`} aria-controls={`offcanvasright${"filterprocess"}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-sliders" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                    </svg></span>
                </div>
              </div>
              <div className="row mt-3 mb-3 me-3 ms-3 item-filter-show">
                <div className="col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso1">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso1">Proceso nivel 1</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso2">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso2">Proceso nivel 2</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso3">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso3">Proceso nivel 3</label>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3 ms-3 me-3 item-filter-show">
                <div className="col-lg-6 col-sm-12">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso1">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso1">Proceso nivel 1</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso2">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso2">Proceso nivel 2</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="proceso3">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="proceso3">Proceso nivel 3</label>
                  </div>
                </div>
              </div>
              <div className="row mb-3 justify-content-end item-filter-show">
                <div className="col-lg-2 col-sm-2 text-end">
                  <button className='btn btn-primary btn-lg me-2' >Buscar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 show-devices">
          <TableProcessComponent />
        </div>
        <div className="row mt-5 show-mobile">
          <div className="col-12">
            <TableProcessMobileComponent />
          </div>
        </div>
        <div className="row mt-5 mb-5 justify-content-end">
          <div className="col-lg-auto col-sm-11">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link disabled" href="#" aria-label="Previous">
                    <span aria-hidden="true">{'<'}</span>
                  </Link>
                </li>
                <li className="page-item"><Link className="page-link" href="#">1</Link></li>
                <li className="page-item"><Link className="page-link" href="#">2</Link></li>
                <li className="page-item"><Link className="page-link" href="#">3</Link></li>
                <li className="page-item">
                  <Link className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">{'>'}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <OffcanvasProcessBiaComponent />
    </>

  )
}

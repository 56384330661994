import React from 'react'

export const OffcanvasEditEvaluationBia = (props) => {
    console.log(props);
    const { process } = props
    const convertIdWithPoitnToLine = (id) => {
        return id.replace(/\./g, '-')
    }
    return (
        <>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasrightedit`} aria-labelledby={`offcanvasrightedit`} role="dialog" >
                <div className="offcanvas-header">

                    <button type="button" className="btn-close text-reset" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasrightedit`} aria-controls={`offcanvasrightedit`}></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="row justify-content-center mt-4 mb-4">
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#ftb528" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="card-title text-center">
                                        <h6>Editando proceso "{"process.process"}" </h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="card-body text-center">
                                        <div className="form-floating mb-3">
                                            <input type="item" className="form-control" id="floatingInput" placeholder="Input" />
                                            <label htmlFor="floatingInput">Example</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-5 mb-5">
                                    <div className="col-4">
                                        <button className='btn btn-primary rounded-pills w-100' type='button'>Si</button>
                                    </div>
                                    <div className="col-4">
                                        <button className='btn btn-secondary rounded-pills w-100' type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasrightedit`} aria-controls={`offcanvasrightedit`}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

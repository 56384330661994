import React, { useRef } from 'react'
import { useEffect } from 'react'
export const PersonalCriticoTab = (props) => {
  const { titleObject, tabOffcanvas, typeOffcanvas } = props;
  const [title, setTitle] = titleObject;
  const [tab, setTab] = tabOffcanvas;
  const [type, setType] = typeOffcanvas;
  const idOffcanvas = `offcanvasright${tab + type}`;
  /* type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`} */
  const handleEditProcess = (idProcess) => {
    setTitle(`Editar personal crítico ${idProcess}`);
    setTab("pcritico");
    setType("editar");
  }
  const handleDeleteProcess = (idProcess) => {
    setTitle(`Eliminar personal crítico ${idProcess}`);
    setTab("pcritico");
    setType("eliminar");
  }
  const popoverRef = useRef()
  useEffect(() => {
    // eslint-disable-next-line no-undef
    var popover = new bootstrap.Popover(popoverRef.current, {
      content: '+56987654321 miraarredondo@bancofalabella.cl',
      placement: 'top',
      /* change style of this popover */
      /* template: `
        <div class="popover" role="tooltip">
          <div class="popover-body"></div>
        </div>
      `, */

    })
  }, [])
  return (
    <>
      <div className="table-responsive">
        <table className='table mt-3 table-striped'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Personal crítico</th>
              <th>Cargo</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>Backup</th>
              <th>Proceso</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Loreto Guerrero Perez</td>
              <td>Sub Gerente de operaciones</td>
              <td>987654321</td>
              <td><a href="mailto:operaciones@bancofalabella.cl" style={{
                color: '#000000'
              }}>operaciones@bancofalabella.cl</a></td>

              <td>Alejandra Mira Arredondo <a type='button' ref={popoverRef}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg></a> </td>
              <td>Abastecimiento y equipamiento sucursales y casa matriz</td>
              <td>
                <span onClick={()=>handleEditProcess("Loreto Guerrero Perez")} type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg></span>
                <span onClick={()=>handleDeleteProcess("Loreto Guerrero Perez")} type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" className="bi bi-trash3" viewBox="0 0 16 16">
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg></span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

    </>
  )
}


import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './offcanvasbodyeditstyle.css'

export const OffcanvasBodyEditProcessComponent = (props) => {
    const { id } = props.process
    const navigate = useNavigate()

    const [primaryItems, setPrimaryItems] = useState([
        'SAP',
        'ATLANTIS',
        'APPIAN'
    ])

    const [secondaryItems, setSecondaryItems] = useState([
        'IMET'
    ])

    const deletePrimaryItems = (index) => {
        const newPrimaryItems = [...primaryItems]
        newPrimaryItems.splice(index, 1)
        setPrimaryItems(newPrimaryItems)
    }

    const deleteSecondaryItems = (index) => {
        const newSecondaryItems = [...secondaryItems]
        newSecondaryItems.splice(index, 1)
        setSecondaryItems(newSecondaryItems)
    }

    const navigateToEvaluacion = () =>{
        navigate('/evaluacion')
    }
  return (
    <>
    <div className="offcanvas-body">
                    <div className="row justify-content-center mt-2 mb-5">
                        <div className="col-12">
                            <h5>Editar proceso {id}</h5>
                            <hr />
                        </div>
                    </div>
                    <div className="row justify-content-end mt-2 mb-2">
                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" className='form-control' id='inputId' value={id} disabled />
                                <label htmlFor="inputId">ID</label>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="selectprocess1">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="selectprocess1">Nivel 1 de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="selectprocess2">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="selectprocess2">Nivel 2 de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="selectprocess3">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="selectprocess3">Nivel 3 de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="selectprocess4">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="selectprocess4">Nivel 4 de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="input-group">
                                <input type="text" className='form-control' id='linkdiagrama' />
                                <span className='input-group-text'><i className="bi bi-paperclip"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea type="text" className='form-control' id='objetivo' rows={5} />
                                <label htmlFor="objetivo">Objetivo / Breve descripción</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="asociado">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="asociado">Tipo de proceso</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" className='form-control' id='especificarproducto' />
                                <label htmlFor="especificarproducto">Especificar producto</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="gerenciaduenia">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="gerenciaduenia">Gerencia dueña</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="gerenteduenio">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="gerenteduenio">Gerente dueño</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="usuarioclave">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="usuarioclave">Usuario clave</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="gerenciasegundalinea">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="gerenciasegundalinea">Gerencia 2a. línea</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="gerentesegundalinea">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="gerentesegundalinea">Gerente 2a. línea</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="roldisenio">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="roldisenio">Rol diseño</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="rolusuarioclave">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="rolusuarioclave">Rol usuario clave</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="sistema">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="sistema">Sistema</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            primaryItems.map((item, index) => {
                                return (
                                    <div className="col-auto ps-0 pe-0" key={index}>
                                        <span className='bg-black-green text-white fw-semibold p-2 rounded-pill'>{item}<i className="ps-1 bi bi-x-circle-fill" onClick={()=>deletePrimaryItems(index)}/></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row justify-content-center mt-4 mb-4">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="proveedor">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="proveedor">Proveedor</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            secondaryItems.map((item, index) => {
                                return (
                                    <div className="col-auto ps-0 pe-0" key={index}>
                                        <span className='bg-black-green text-white fw-semibold p-2 rounded-pill'>{item}<i className="ps-1 bi bi-x-circle-fill" onClick={()=>deleteSecondaryItems(index)}/></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row justify-content-center mt-5 mb-2">
                        <div className="col-12">
                            <h6 className='fw-semibold text-gray'>Personal crítico y backup</h6>
                            <hr />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-0 mb-2">
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="personalcritico">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="personalcritico">Personal crítico</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="backup">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="backup">Backup</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-2">
                        <div className="col-3">
                            <span className='text-decoration-underline text-gray fw-semibold' data-bs-dismiss="offcanvas" aria-label="Close" >CANCELAR</span>
                        </div>
                        <div className="col-6 text-end">
                            <span className="text-decoration-underline text-green fw-semibold" data-bs-dismiss="offcanvas" aria-label="Close" aria-current="page" onClick={navigateToEvaluacion} >EVALUACIÓN BIA</span>
                        </div>
                        <div className="col-3">
                            <button className='btn btn-primary' type='button' data-bs-dismiss="offcanvas" aria-label="Close">Guardar</button>
                        </div>

                    </div>
                </div>
    </>
  )
}

import React from 'react'
import { CriticalstaffAddForm } from './offcanvasforms/AddForms/CriticalstaffAddForm';
import { ProviderAddForm } from './offcanvasforms/AddForms/ProviderAddForm';
import { SystemAddForm } from './offcanvasforms/AddForms/SystemAddForm';

export const OffcanvasComponent = (props) => {
    console.log("OffCanvas Maintainer:", props);
    return (
        <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasright${props.tab + props.type}`} aria-labelledby={`offcanvasright${props.tab + props.type}`}>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id={`offcanvasright${props.tab + props.type}`}>{props.title}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                {
                    (props.tab === 'sistema' && props.type === 'crear') && <SystemAddForm tab={props.tab} type={props.type} />
                }
                {
                    (props.tab === 'proveedor' && props.type === 'crear') && <ProviderAddForm tab={props.tab} type={props.type} />
                }
                {
                    (props.tab === 'pcritico' && props.type === 'crear') && <CriticalstaffAddForm tab={props.tab} type={props.type} />
                }
                {
                    (props.type === 'editar' ||  props.type==='eliminar' ) && <>
                        <div className="col-12">
                            <h4>
                                Formulario en construcción
                            </h4>
                        </div>
                    </>
                }
            </div>
        </div>
    )

}

import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { OffcanvasBodyEditProcessComponent } from './OffcanvasBody/OffcanvasBodyEditProcessComponent'
import { OffcanvasDeleteProcessComponent } from './OffcanvasBody/OffcanvasDeleteProcessComponent'

export const OffcanvasProcessBiaComponent = () => {
    const selectProcess = useSelector(state => state.selectProcess.value)
    const convertPointToLine = (id = '') => {
        return id.replace(/\./g, '-')
    }
    const returnNameOfAction = (option) => {
        switch (option) {
            case 1:
                return 'Mostrar proceso'
            case 2:
                return 'Copiar proceso'
            case 3:
                return 'Editar proceso'
            case 4:
                return 'Eliminar proceso'
            default:
                return 'Filtro'
        }
    }
    useEffect(() => {
        console.log("selectProcess[useEffect]", selectProcess);
    }, [selectProcess])

    if (Object.entries(selectProcess).length > 0) {
        return (
            <div className="offcanvas offcanvas-end" data-bs-backdrop="static" tabIndex="-1" id={`offcanvasright${convertPointToLine(selectProcess.id)}`} aria-labelledby={`offcanvasright${convertPointToLine(selectProcess.id)}`} role="dialog" >
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                {
                    selectProcess.option === 3 && 
                    <OffcanvasBodyEditProcessComponent process={selectProcess} />
                }
                {
                    selectProcess.option === 4 &&
                    <OffcanvasDeleteProcessComponent selectProcess={selectProcess} />
                    
                }
            </div>
        )
    } else {
        return <>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasright${"filterprocess"}`} aria-labelledby={`offcanvasright${"filterprocess"}`} role="dialog" >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id={`offcanvasright${"filterprocess"}`}>Filtro de busqueda</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-12">
                            <button className='btn btn-primary btn-lg w-100' type='button' data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${"filterprocess"}`} aria-controls={`offcanvasright${"filterprocess"}`}>Buscar</button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    }



}

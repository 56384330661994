import './App.css';

import { Rutas } from './routes/Rutas';
function App() {
  return (
    <>
      <Rutas />
    </>
  );
}

export default App;

import React from 'react'
import { BadgeStatusComponent } from '../../badge-status/BadgeStatusComponent'
import './itemprocessstyles.css'
import { SecondaryDetailItemTableProcessComponent } from './SecondaryDetailItemTableProcessComponent'
export const MainDetailItemTableProcessComponent = (props) => {
    //TODO:  habilitar linea
    // eslint-disable-next-line
    const { convertPointToLine, process } = props
    return (
        <>
            <div className="container-fluid ">
                <div className="row mt-3 mb-3">
                    <div className="col-3 border-right">

                        <div className="row">
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        <strong>FINANCIERO</strong>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                        <BadgeStatusComponent value={2} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        Bajo
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Medio
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Alto
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Extremo
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        0 a 2 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        4 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        6 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        8 horas
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-4 border-right">
                        <div className="row">
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-4">
                                        <strong>REPUTACIONAL</strong>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                        <BadgeStatusComponent value={4} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        4
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        Bajo
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Medio
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Alto
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Extremo
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        0 a 2 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        4 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        6 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        8 horas
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ms-1">
                        <div className="row">
                            <div className="col-4">
                                <div className="row text-center">
                                    <div className="col-4">
                                        <strong>REGULATORIO</strong>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                        <BadgeStatusComponent value={8} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        8
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        Bajo
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Medio
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Alto
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        Extremo
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-12">
                                        0 a 2 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        4 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        6 horas
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        8 horas
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        <div className="card bg-light-grey ps-2 pe-2 pt-2 pb-2">
                            <div className="row mt-3">
                                <div className="col-2">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>Sistema</h6>
                                        </div>
                                        <div className="col-12">
                                            SAP / Telemarketing
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>Proveedor</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            IMET
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>Personal crítico</h6>
                                        </div>
                                        <div className="col-12">
                                            Carolina Carrasco Pinilla
                                        </div>
                                        <div className="col-12">
                                            Camila Bermúdez Soto
                                        </div>
                                    </div>



                                </div>
                                <div className="col-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>Backup</h6>
                                        </div>
                                        <div className="col-12">
                                            Rodrigo Guerrero Venegas
                                        </div>
                                        <div className="col-12">
                                            Alejandro Guerrero Venegas
                                        </div>
                                    </div>


                                </div>
                                <div className="col-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>RTO</h6>
                                        </div>
                                        <div className="col-12">
                                            45 minutos
                                        </div>
                                    </div>

                                </div>
                                <div className="col-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className='text-black'>MTPD</h6>
                                        </div>
                                        <div className="col-12">
                                            24 minutos
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 justify-content-end mb-5">
                    <div className="col-10">
                        <div className="dropdown text-end">
                            <span className="showmorebutton fw-semibold" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#43b12e" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                                VER MÁS INFO
                            </span>
                            <ul className="dropdown-menu w-100 p-0 without-border">
                                <SecondaryDetailItemTableProcessComponent process={process} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

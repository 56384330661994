import React, { useState } from 'react'
import { useEffect } from 'react'

export const ImpactProcessEvaluationComponent = (props) => {
    
    const [type, setType] = useState("secondary")
    const [text, setText] = useState("No Crítico")
    const types = [ "secondary","success","warning","danger" ]
    const texts = [ "No Crítico","Normal","Alto","Crítico" ]
    useEffect(()=>{
        switch (props.impact) {
            case 1:
                setType(types[1])
                setText(texts[1])
                break;
            case 2:
                setType(types[2])
                setText(texts[2])
                break;
            case 3:
                setType(types[3])
                setText(texts[3])
                break;
            default:
                setType(types[0])
                setText(texts[0])
        }
    },[props])


  return (
    <span className={`badge text-bg-${type} w-75`}>{text}</span>
  )
}

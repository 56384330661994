import React from 'react'

import './statusformstyle.css'
export const StatusFormAccept = (props) => {
    const closeForm = () => {
        props.stateSystemForm[0](true)
        props.stateSystemForm[1](false)
    }
    return (
        <div className="card">
            <div className="row justify-content-center">
                <div className="col-8 text-center  mt-3 mb-3">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#007937" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div className="col-8 text-center  mt-3 mb-3">
                    <h3>Felicidades</h3>
                </div>
                <div className="col-8 text-center  mt-3 mb-3">
                    {
                        props.typeTab === 'sistema' & props.type === 'crear' && <h5>Se ha creado el proceso "{props.process}" en el {props.system} con éxito.</h5>
                    }
                    
                </div>
                <div className="col-8 text-center  mt-3 mb-3">
                    <button className='btn btn-primary' data-bs-dismiss="offcanvas" aria-label="Close"  onClick={closeForm}>Entendido</button>
                </div>
            </div>
        </div>
    )
}

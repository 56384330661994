import React from 'react'
import './sistematabstyle.css'

export const SistemaTab = (props) => {
  console.log("SistemaTab:", props);
  const { titleObject, tabOffcanvas, typeOffcanvas } = props;
  const [title, setTitle] = titleObject;
  const [tab, setTab] = tabOffcanvas;
  const [type, setType] = typeOffcanvas;
  const idOffcanvas = `offcanvasright${tab + type}`;
  /* type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`} */
  const handleEditProcess = (idProcess) => {
    setTitle(`Editar Sistema ${idProcess}`);
    setTab("sistema");
    setType("editar");
  }
  const handleDeleteProcess = (idProcess) => {
    setTitle(`Eliminar Sistema ${idProcess}`);
    setTab("sistema");
    setType("eliminar");
  }

  return (
    <>
      <div className="table-responsive">
        <table className='table mt-3 table-striped'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Sistema</th>
              <th>Proceso</th>
              <th>Descripción</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SO.PCYF.1</td>
              <td>Soporte</td>
              <td>Abastecimiento y equipamiento sucursales y casa matriz</td>
              <td>Lorem ipsum, dolor sit amet consectetur adipisicing... </td>
              <td>
                <span onClick={() => handleEditProcess('SO.PCYF.1')} type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </span>
                <span onClick={() => handleDeleteProcess('SO.PCYF.1')} type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idOffcanvas}`} aria-controls={`${idOffcanvas}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-trash3" viewBox="0 0 16 16">
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </span>
              </td>
            </tr>
            {/* <tr>
              <td>SO.OYS.1</td>
              <td>Soporte</td>
              <td>Abono 10% AFP-Abono en cuenta</td>
              <td>Lorem ipsum, dolor sit amet consectetur adipisicing... </td>
              <td>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg></span>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-trash3" viewBox="0 0 16 16">
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg></span>
              </td>
            </tr> */}
            {/* <tr>
              <td>SO.OYS.2</td>
              <td>Soporte</td>
              <td>Abono 10% AFP-Abono en cuenta corriente</td>
              <td>Lorem ipsum, dolor sit amet consectetur adipisicing... </td>
              <td>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg></span>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" className="bi bi-trash3" viewBox="0 0 16 16">
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg></span>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { ItemTabProcessEvaluationComponent } from './ItemTabProcessEvaluation/ItemTabProcessEvaluationComponent'

import './tabprocessevaluation.css'

export const TabsProcessEvaluation = () => {
  return (
    <div className="col-12">
      <div className="card pt-2 pb-2 ps-2 pe-2">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link className="nav-link active ps-2 pe-2" aria-current="page" to="#financiero" data-bs-toggle="tab">Financiero</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link ps-2 pe-2" aria-current="page" to="#reputacional" data-bs-toggle="tab">Reputacional</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link ps-2 pe-2" aria-current="page" to="#regulatorio" data-bs-toggle="tab">Regulatorio</Link>
          </li>
        </ul>
      </div>
      <div className="tab-content mt-5 mb-5">
        <div className="tab-pane fade show active" id='financiero' >
          <ItemTabProcessEvaluationComponent tab={'financiero'} key="financiero" />
        </div>
        <div className="tab-pane fade" id='reputacional'>
          <ItemTabProcessEvaluationComponent tab={'reputacional'} key="reputacional" />
        </div>
        <div className="tab-pane fade" id='regulatorio'>
          <ItemTabProcessEvaluationComponent tab={'regulatorio'} key="regulatorio" />
        </div>
      </div>
    </div>
  )
}

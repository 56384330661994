import React, { useState } from 'react'
import { OffcanvasEvaluationBiaComponent } from '../components/offcanvas/evaluacionbia/OffcanvasEvaluationBiaComponent'
import { TableEvaluationBiaComponent } from '../components/TableEvaluation/TableEvaluationBiaComponent'
import '../styles/evaluationbiastyle.css'
export const EvaluacionBiaPage = () => {
  const [processSelected, setProcessSelected] = useState({})
  const [optionProcessSelected, setOptionProcessSelected] = useState(3)


  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-12">
            <h3 className='fw-semibold'>Evaluación Bia</h3>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="card" style={
              {
                "background": "#F0F6F3"
              }
            }>
              <div className="row ms-2 me-2 mt-2 mb-2 item-small-filter">
                <div className="col-9">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-2">
                  <span className='btn btn-light' type='button' data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${"filterEvaluationBia"}`} aria-controls={`offcanvasright${"filterEvaluationBia"}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-sliders" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                    </svg></span>
                </div>
              </div>
              <div className="row ms-2 me-2 item-filter-show">
                <div className="col-lg-2 col-sm-12 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-7 col-sm-12  mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12  mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
              </div>
              <div className="row ms-2 me-2 item-filter-show">
                <div className="col-lg-2 col-sm-6 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mt-2 mb-2">
                  <div className="form-floating">
                    <select className="form-control" defaultValue={"0"} id="typeprocess">
                      <option disabled value="0">Selecciona opción</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label htmlFor="typeprocess">Tipo de proceso</label>
                  </div>
                </div>

              </div>
              <div className="row ms-2 me-2 justify-content-end item-filter-show">
                <div className="col-lg-1 col-sm-12 mt-2 mb-2">
                  <button className='btn btn-primary rounded-pill w-100'>Buscar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableEvaluationBiaComponent setProcessSelected={setProcessSelected} setOptionProcessSelected={setOptionProcessSelected} />
      <OffcanvasEvaluationBiaComponent processSelectedState={[processSelected, setProcessSelected]} optionProcessSelectedState={[optionProcessSelected, setOptionProcessSelected]} />
    </>
  )
}

import React from 'react'

export const SecondaryDetailItemTableProcessMobileComponent = (props) => {
    const { data } = props.process
    console.log("SecondaryDetailItemTableProcessMobileComponent",data);
  return (
    <div className="card bg-light-grey p-3">
        <div className="row">
            <div className="col">
                <h5 className='m-0'>Sistema</h5>
                <p className='text-dark-gray'>{data.system}</p>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h5 className='m-0'>Proveedor</h5>
                <p className='text-dark-gray'>{data.provider}</p>
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <h5 className='m-0'>Personal crítico</h5>
                {
                    data.criticalPersonal.map(item=>{
                        return(
                            <p className='text-dark-gray m-0' key={item}>{item}</p>
                        )
                    })
                }
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <h5 className='m-0'>Backup</h5>
                {
                    data.backup.map(item=>{
                        return(
                            <p className='text-dark-gray m-0' key={item}>{item}</p>
                        )
                    })
                }
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h5 className='m-0'>RTO</h5>
                <p className='text-dark-gray'>{data.rto.minutes} minutos</p>
            </div>
            <div className="col">
                <h5 className='m-0'>MTPD</h5>
                <p className='text-dark-gray'>{data.mtpd.minutes} minutos</p>
            </div>
        </div>
    </div>
  )
}

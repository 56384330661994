import React from 'react'

export const ItemTabProcessEvaluationComponent = (props) => {
    const { tab } = props
    const rango = ['bajo', 'medio', 'alto', 'extremo']
    const icon = ["bi bi-check-circle-fill", "bi bi-dash-circle-fill", "bi bi-exclamation-circle-fill", "bi bi-x-circle-fill"]
    //TODO: solucionar el problema de key 
    return (
        <div className="row" key={tab} >
            {
                rango.map((item, index) => {
                    return <div className="col-lg-3 col-6" key={tab + item + index}>
                        <div className="row">
                            <div className="col-12">
                                <p className='text-capitalize fw-semibold' style={
                                    {
                                        color: item === 'bajo' ? '#28a745' : item === 'medio' ? '#6c757d' : item === 'alto' ? '#ffc107' : '#dc3545'
                                    }
                                }><span className={`${icon[index]}`} ></span> {item}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className='text-muted'>Pérdida agregada cuya severidad es menor a $50.000.000</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating">
                                    <select className="form-control fw-semibold" defaultValue={"1"} id={`${tab + item}`}>
                                        <option value="1">De 0 a 2 horas</option>
                                        <option value="2">4 horas</option>
                                        <option value="3">6 horas</option>
                                        <option value="4">8 horas</option>
                                    </select>
                                    <label htmlFor="typeprocess">Indica el rango de tiempo:</label>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

import React, { useState } from 'react'
import { useEffect } from 'react'
import { OffcanvasDeleteEvaluationBia } from './OffcanvasDelete/OffcanvasDeleteEvaluationBia';
import { OffcanvasEditEvaluationBia } from './OffcanvasEdit/OffcanvasEditEvaluationBia';

export const OffcanvasEvaluationBiaComponent = (props) => {
    const [processSelected, setProcessSelected] = props.processSelectedState
    const [optionProcessSelected, setOptionProcessSelected] = props.optionProcessSelectedState

    useEffect(() => {

    }, [optionProcessSelected])
    return (
        <>  <OffcanvasEditEvaluationBia process={processSelected} setProcess={setProcessSelected} />
            <OffcanvasDeleteEvaluationBia process={processSelected} setProcess={setProcessSelected} />
            <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasright${"filterEvaluationBia"}`} aria-labelledby={`offcanvasright${"filterEvaluationBia"}`} role="dialog" >
                <div className="offcanvas-header">

                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">

                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="row justify-content-center mt-2 mb-5">
                        <div className="col-10">
                            <h5>Filtro de busqueda</h5>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess2">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess2">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess3">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess3">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess4">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess4">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess5">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess5">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess6">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess6">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2 mb-2">
                        <div className="col-10">
                            <div className="form-floating">
                                <select className="form-control" defaultValue={"0"} id="typeprocess7">
                                    <option disabled value="0">Selecciona opción</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label htmlFor="typeprocess7">Tipo de proceso</label>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center  mt-4 mb-4">
                        <div className="col-10">
                            <button className='btn btn-primary rounded-pill w-100' data-bs-dismiss="offcanvas" aria-label="Close">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

/*

{
                                (option === 2 && actionConcreted === 0) && <OffcanvasDeleteEvaluationBia />

                            }
                            {
                                (option === 1 && actionConcreted === 0) && <OffcanvasEditEvaluationBia />
                            }{ actionConcreted === 1 &&
                                <OffcanvasProcessOk />
                            }
                            {
                                (option === 3) && <div className="card">
                                    HOla
                                </div>
                            }
*/

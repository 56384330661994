import { useSelector } from 'react-redux'
import { ItemTableProcess } from './desktop/ItemTableProcess'

import './tableprocessstyle.css'

export const TableProcessComponent = (props) => {
  console.log(props);
  const listProcess = useSelector(state => state.listProcess.value)
  console.log("listProcess",props);
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className='text-dark-gray'>ID</th>
              <th scope="col" className='text-dark-gray'>Tipo de proceso</th>
              <th scope="col" className='text-dark-gray'>Proceso</th>
              <th scope="col" className='text-dark-gray'>Sistema</th>
              <th scope="col" className='text-dark-gray'>Nivel</th>
              <th scope="col" className='text-dark-gray'>Estado</th>
              <th scope="col" className='text-dark-gray'></th>
            </tr>
          </thead>
          <tbody>
            {
              listProcess.map((item, index) => (
                <ItemTableProcess process={item} key={index} setIdProcess={props.setIdProcess} setTypeAction={props.setTypeAction}/>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

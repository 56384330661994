import React from 'react'
import { convertIdProcessPointsToLine } from '../../../helpers/convertIdProcessPointsToLine';
import { BadgeStatusComponent } from '../../badge-status/BadgeStatusComponent';
import { SecondaryDetailItemTableProcessMobileComponent } from './SecondaryDetailItemTableProcessMobileComponent';

export const MainDetailItemTableProcessMobileComponent = (props) => {
    const { process } = props
    const idAux = process.id?convertIdProcessPointsToLine(process.id)+'offcanvasmoreinfo':'offcanvasmoreinfo';
    return (
        <div className="card bg-white">
            <div className="card-body">
                <div className="row">
                    <div className="col ps-0 pe-0">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <p className='m-0 text-center'>FINANCIERO</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <BadgeStatusComponent value={process.financial} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6 className='fw-semibold text-dark-gray text-center'>
                                    {process.financial}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto ps-2">
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Bajo</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Medio</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Alto</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Extremo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'>De 0 a 2 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 4 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 8 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 6 horas</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col ps-0 pe-0">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <p className='m-0 text-center'>REPUTACIONAL</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <BadgeStatusComponent value={process.reputational} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6 className='fw-semibold text-dark-gray text-center'>
                                    {process.reputational}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto ps-2">
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Bajo</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Medio</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Alto</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Extremo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'>De 0 a 2 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 4 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 8 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 6 horas</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col ps-0 pe-0">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <p className='m-0 text-center'>REGULATORIO</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <BadgeStatusComponent value={process.regulatory} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6 className='fw-semibold text-dark-gray text-center'>
                                    {process.regulatory}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto ps-2">
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Bajo</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Medio</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Alto</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='text-dark-gray mb-1'>Extremo</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'>De 0 a 2 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 4 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 8 horas</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <p className='fw-semibold text-dark-gray mb-1'> 6 horas</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <SecondaryDetailItemTableProcessMobileComponent process={process} />
                </div>
                <div className="row mt-3">
                    <div className="col text-end">
                        <span className="showmorebutton fw-semibold" type="button" data-bs-toggle="offcanvas" data-bs-target={`#${idAux}`} aria-controls={`${idAux}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#43b12e" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                            </svg>
                            VER MÁS INFO
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'

export const SecondaryDetailItemTableProcessComponent = (props) => {
    const { process } = props
    return (
        <div className="container-fluid p-0 shadow-box without-border">
            <div className="row justify-content-end">
                <div className="col-12 ps-1 pe-1">
                    <div className="row bg-light-green">
                        <div className="col-1">
                            <div className="col-12">
                                <p className='text-muted mb-0'>ID</p>
                            </div>
                            <div className="col-12">
                                {process.id}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Tipo de proceso</p>
                            </div>
                            <div className="col-12">
                                {process.typeProcess}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Sistema</p>
                            </div>
                            <div className="col-12">
                                data.system{/* {process.data} */}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Proveedor</p>
                            </div>
                            <div className="col-12">
                                data.Proveedor {/* {process.data} */}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Personal crítico</p>
                            </div>
                            data.criticalPersonal
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 bg-white">
                    <div className="row">
                        <div className="col-3">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Nivel 1 proceso</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Nivel 2 proceso</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Nivel 3 proceso</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Nivel 4 proceso</p>
                                <p>Info</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Objetivo / Breve descripción</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Link diagrama</p>
                                <a href="https://bancofalabella.cl/" className='text-green fw-semibold'>https://bancofalabella.cl/</a>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Asociado a producto o servicio</p>
                                <p>Info</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Especificar producto o servicio asociado</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Gerencia dueña</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Gerente dueño</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Dueño del proceso</p>
                                <p>Info</p>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="col-12">
                                <p className='text-muted mb-0'>Usuario clave</p>
                                <p>Info</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

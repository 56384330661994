import React from 'react'

export const OffcanvasProcessEvaluationComponent = (props) => {
    const { process } = props
    const convertIdWithPointsToLines = (idPoint = '') => {
        return idPoint.replace(/\./g, '-')
    }

    return (
        <>
            <div className="offcanvas offcanvas-end" data-bs-backdrop="static" tabIndex="-1" id={`offcanvasright${convertIdWithPointsToLines(process.id)}`} aria-labelledby={`offcanvasright${convertIdWithPointsToLines(process.id)}`} role="dialog" >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title fw-bold" id={`offcanvasright${convertIdWithPointsToLines(process.id)}`}>Guardar BIA</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="row justify-content-center mt-4 mb-4">
                                    <div className="col-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#007937" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="card-title text-center">
                                        <h5 className='fw-bold'>Felicitaciones</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="card-body text-center">
                                        El proceso <strong>"{process.process}"</strong> se ha guardado con exito.
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-5 mb-5">
                                    <div className="col-10">
                                        <button className='btn btn-primary rounded-pills w-100' type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${convertIdWithPointsToLines(process.id)}`} aria-controls={`offcanvasright${convertIdWithPointsToLines(process.id)}`}>Entendido</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

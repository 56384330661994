import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setProcess } from '../../../features/process/selectProcess'
import { ToggleSwitchComponent } from '../../toggle-switch/ToggleSwitchComponent'

import './itemprocessstyles.css'
import { MainDetailItemTableProcessComponent } from './MainDetailItemTableProcessComponent'
export const ItemTableProcess = (props) => {
    const { process } = props
    const [idUrl, setIdUrl] = useState("")
    const [showBorderGreen, setShowBorderGreen] = useState(false)

    const convertPointToLine = (id = '') => {
        return id.replace(/\./g, '-')
    }
    const navigate = useNavigate()
    const handleSelectItem = () =>{
        setShowBorderGreen(!showBorderGreen)
    }
    const evaluateProcessIdSelected = () =>{
        navigate(idUrl)
    }
    useEffect(()=>{
        setIdUrl(`/proceso/evaluacion/${convertPointToLine(process.id)}`)
    },[process.id])
    const dispatch = useDispatch()
    /* const selectProcess = useSelector(state => state.selectProcess.value) */
    const handleShowOffcanvas = (e, option) => {
        console.log(option);
        e.preventDefault()
        const payload = {
            ...process,
            option
        }
        console.log(payload);
        dispatch(setProcess(payload))
    }
    return (
        <>
            <tr className={`${showBorderGreen?"item-selected-up":""}`}>
                <th scope="row" className='fw-semibold'>{process.id}</th>
                <td>{process.typeProcess}</td>
                <td>{process.process}</td>
                <td>{process.system}</td>
                <td className='text-center'>
                    {process.level}
                </td>
                <td>
                    <ToggleSwitchComponent active={process.state} />
                </td>
                <td>

                    <span onClick={evaluateProcessIdSelected} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" className="bi bi-clipboard2-check itembutton" viewBox="0 0 16 16">
                            <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                            <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                            <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
                        </svg>
                    </span>
                    <span onClick={(e) => handleShowOffcanvas(e, 2)} data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${convertPointToLine(process.id)}`} aria-controls={`offcanvasright${convertPointToLine(process.id)}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" className="bi bi-files itembutton" viewBox="0 0 16 16">
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                        </svg>
                    </span>
                    <span onClick={(e) => handleShowOffcanvas(e, 3)} data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${convertPointToLine(process.id)}`} aria-controls={`offcanvasright${convertPointToLine(process.id)}`}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" className="bi bi-pencil itembutton" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg></span>
                    <span onClick={(e) => handleShowOffcanvas(e, 4)} data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${convertPointToLine(process.id)}`} aria-controls={`offcanvasright${convertPointToLine(process.id)}`}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" className="bi bi-trash3 itembutton" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg></span>
                    <span data-bs-toggle="collapse" href={`#${convertPointToLine(process.id)}`} role="button" aria-expanded="false" aria-controls={`${convertPointToLine(process.id)}`} onClick={()=>handleSelectItem()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" className="bi bi-chevron-down itembutton" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </span>
                </td>
            </tr>
            <tr className={`${showBorderGreen?"collapse fade item-selected-down":"collapse fade"}`} id={`${convertPointToLine(process.id)}`}>
                <td colSpan="7">
                    <MainDetailItemTableProcessComponent process={process} convertPointToLine={convertPointToLine} />
                </td>
            </tr>
        </>
    )
}

import { configureStore } from '@reduxjs/toolkit'
import listProcessReducer from '../features/process/listProcessSlice'
import systemReducer from '../features/system/systemSlice'
import selectProcessReducer from '../features/process/selectProcess'
import userReducer from '../features/user/useSlice'
export default configureStore({
  reducer: {
    listProcess: listProcessReducer,
    selectProcess: selectProcessReducer,
    system: systemReducer,
    user: userReducer
  }
})
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { TabsProcessEvaluation } from '../components/TableProcessEvaluation/TabsProcessEvaluation'
import { SecondaryDetailItemTableProcessComponent } from '../components/TableProcess/desktop/SecondaryDetailItemTableProcessComponent'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { OffcanvasProcessEvaluationComponent } from '../components/offcanvas/processevaluation/OffcanvasProcessEvaluationComponent'


export const ProcessEvaluationPage = () => {
    const [realId, setRealId] = useState("")
    const listProcess = useSelector(state => state.listProcess.value)
    const [process, setProcess] = useState({})
    const { id } = useParams()

    useEffect(() => {
        const convertIdlinetoPoint = () => {
            const idLine = id.split('-').join('.')
            setRealId(idLine)
            setProcess(listProcess.find(process => process.id === idLine))
        }
        convertIdlinetoPoint()
    }, [id, listProcess])


    return (
        <>
            <div className="container-fluid">
                <div className="row mt-5 mb-3">
                    <div className="col-lg-9 col-sm-12">
                        <div className="row">
                            <div className="col-12">
                                <h3 className='fw-semibold'>Procesos BIA</h3>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-2 col-sm-12">
                                        <div className="col-12">
                                            <p className='text-muted'>ID</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>{realId}</p>
                                            {/* <p>{process.id}</p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-12">
                                        <div className="col-12">
                                            <p className='text-muted'>Tipo de proceso</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>Soporte</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <div className="col-12">
                                            <p className='text-muted'>Nivel 4 Proceso</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>Abastecimiento y equipamiento sucursales y casa matriz</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <div className="col-12">
                                            <p className='text-muted'>Sistema</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>SAP / Telemarketing</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-sm-5">
                                        <div className="col-12">
                                            <p className='text-muted'>Proveedor</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>IMET</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-5">
                                        <div className="col-12">
                                            <p className='text-muted'>Personal Crítico</p>
                                        </div>
                                        <div className="col-12">
                                            <p className='fw-semibold'>Carolina Castillo Pinilla / Rodrigo Guerrero Venegas</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-2 mt-lg-5 text-end">
                                        <span data-bs-toggle="collapse" href={`#${id}collapsesecondary`} role="button" aria-expanded="false" aria-controls={`${id}collapsesecondary`} style={
                                            {
                                                color: '#43b12e',
                                            }
                                        }><span className="bi bi-plus-circle-fill"></span> Ver más info</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="collapse" id={`${id}collapsesecondary`}>
                            <div className="row mt-3">
                                <SecondaryDetailItemTableProcessComponent process={process} />
                            </div>
                        </div>
                        <div className="row">
                            <TabsProcessEvaluation />
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-auto">
                                        <p className='text-muted'> <i className="bi bi-calendar3"></i> Última actualización:</p>
                                    </div>
                                    <div className="col-auto">
                                        <p className='text-muted fw-semibold'>23 Sept. del 2022</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="card ps-3 pe-3 pt-2 pb-2">
                                    <div className="row">
                                        <h6 className='fw-semibold'>Evaluación</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Financiero</p>
                                        </div>
                                        <div className="col">
                                            <p className='text-extremo'>Extremo</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>7</strong></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Reputacional</p>
                                        </div>
                                        <div className="col">
                                            <p>Bajo</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>2</strong></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Regulatorio</p>
                                        </div>
                                        <div className="col">
                                            <p>Medio</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>3</strong></p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col">
                                            <p><strong>Criterio</strong></p>
                                        </div>
                                        <div className="col">
                                            <span className="badge text-bg-danger w-25" data-bs-togle="hover" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Top popover">7</span>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Impacto</p>
                                        </div>
                                        <div className="col">
                                            <p className='text-danger fw-bold'>Crítico</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Priorización</p>
                                        </div>
                                        <div className="col">
                                            <p className='fw-bold'>Vital</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="rtoinput" />
                                                <label htmlFor="rtoinput">Ingresa RTO</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="mtpdinput" />
                                                <label htmlFor="mtpdinput">Ingresa MTPD</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-end">
                                        <div className="col-4">
                                            <button className='btn btn-primary rounded-pills' type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasright${id}`} aria-controls={`offcanvasright${id}`}>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OffcanvasProcessEvaluationComponent process={process} />
        </>

    )
}

import React, { useEffect, useState } from 'react'
import { OffcanvasComponent } from '../components/offcanvas/mantenedor/OffcanvasComponent'
import { TabMaintainer } from '../components/TableMaintainer/TabMaintainer'

export const MaintainerPage = () => {
  const [titleOffcanvas, setTitleOffcanvas] = useState("")
  const [tabOffcanvas, setTabOffcanvas] = useState("")
  const [typeOffcanvas, setTypeOffcanvas] = useState("")
  useEffect(() => {
    setTitleOffcanvas("Crear sistema")
    setTabOffcanvas("sistema")
    setTypeOffcanvas("crear")
}, [])
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-lg-4 col-sm-12">
            <h3 className='fw-semibold'>Mantenedor</h3>
          </div>
        </div>
        <div className="row mt-5 justify-content-end">
          <div className="col-lg-4 col-sm-8 ">
            <div className="input-group">
              <div className="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg></div>
              <input type="text" className="form-control" id="searchmaintainer" placeholder="Busqueda..." />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TabMaintainer titleOffcanvas={[titleOffcanvas, setTitleOffcanvas]} tabOffcanvas={[tabOffcanvas, setTabOffcanvas]} typeOffcanvas={[typeOffcanvas, setTypeOffcanvas]}/>
          </div>
        </div>
      </div>
      <OffcanvasComponent title={titleOffcanvas} tab={tabOffcanvas} type={typeOffcanvas} />
    </>
  )
}

import React from 'react'
import './toggleswitchstyle.css'

export const ToggleSwitchComponent = (props) => {
    return (
        <label className="switch btn-color-mode-switch">
            <input type="checkbox" name="switchstate" id="switchstate" checked={!props.active} disabled/>
            <label htmlFor="switchstate" data-on="Inactivo" data-off="Activo" className="btn-color-mode-switch-inner"></label>
        </label>
    )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { ItemProcessEvaluationBiaComponent } from './itemProcessEvaluation/ItemProcessEvaluationBiaComponent'

export const TableEvaluationBiaComponent = (props) => {
  const listProcess = useSelector(state => state.listProcess.value)
  const { setProcessSelected } = props
  const { setOptionProcessSelected } = props
  console.log(props);
  return (
    <>
      <div className="container-fluid ps-5 pe-5 mt-5">
        <div className="table-responsive">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Actualización</th>
                <th>Tipo de Proceso</th>
                <th>Proceso</th>
                <th>Nivel</th>
                <th>Resultado</th>
                <th>Impacto</th>
                <th>Priorización</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                listProcess.map((item, index) => (
                  <ItemProcessEvaluationBiaComponent setProcess={setProcessSelected} setOption={setOptionProcessSelected} process={item} key={index} />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

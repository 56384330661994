import React from 'react'

export const OffcanvasDeleteProcessComponent = (props) => {
    const { selectProcess } = props
    return (
        <div className="offcanvas-body">
            <div className="row mb-2">
                <div className="col">
                    <h3 className='fw-bold'>{"Eliminar proceso"}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card pt-4 pb-4">
                        <div className="row">
                            <div className="col text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-exclamation-circle-fill rotate-180" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                </svg>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <h5 className='fw-semibold'>
                                    ¿Estás seguro que deseas eliminar este proceso?
                                </h5>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-10 text-center">
                                <p className='text-muted'>
                                    {selectProcess.process}
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2 mb-2">
                            <div className="col-10">
                                <button className='btn btn-primary w-100'> De acuerdo</button>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2 mb-4">
                            <div className="col text-center">
                                <span className='fw-semibold text-green'>Cancelar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

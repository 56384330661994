
import React from 'react'
import { useSelector } from 'react-redux'
import { CanvasMoreInfoMobileComponent } from './CanvasMoreInfoMobileComponent';
import { ItemTableProcessMobileComponent } from './ItemTableProcessMobileComponent';

export const TableProcessMobileComponent = () => {
    const listProcess = useSelector(state => state.listProcess.value)

    return (
        <>
            {
                listProcess.map((process, index) => {
                    return <ItemTableProcessMobileComponent process={process} key={index} />
                })
            }
            <CanvasMoreInfoMobileComponent />
        </>
    )
}
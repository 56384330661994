import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './navbarstyle.css'
import brand from '../../assets/android-chrome-256x256.webp'
import { useEffect } from 'react'
//TODO: Al ser movil, el despliegue del navbar es un CANVAS

export const NavbarComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const [profileName, setProfileName] = useState("Gabriel Sepúlveda") //TODO: remember change for userProfile redux
  const [openNavbar, setOpenNavbar] = useState(false)
  const [initialsName, setInitialsName] = useState("")

  useEffect(() => {
    const getInitials = () => {
      const name = profileName.split(" ")
      let initials = ""
      for (let index = 0; index < 2; index++) {
        initials = initials + name[index][0];
      }
      setInitialsName(initials)
    }
    getInitials()
  }, [profileName])

  const handleNavbar = () => {
    setOpenNavbar(!openNavbar)
  }

  return (
    <nav className="navbar navbar-expand-lg bg-white sticky-top">
      <div className="container-fluid">
        <div className="row">
        <div className="col-3">
        <NavLink className="navbar-brand" to="/"> <img src={brand} alt="falabella-brand" width="50%" /> </NavLink>
        </div>
        <div className="col-auto">
        <button className='btn btn-bg-green-light rounded-pills show-movile' data-bs-toggle="offcanvas" data-bs-target="#offcanvascreateprocess" aria-controls="#offcanvascreateprocess"  >Crear proceso</button>
        
        </div>
        <div className="col-auto">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>handleNavbar()}>
          <span className="navbar-toggler-icon"></span>
        </button>
        </div>
        </div>
        <div className={`collapse navbar-collapse fader ${openNavbar?'show':''}`} id="navbarNav">
          <ul className="navbar-nav text-start">
            <li className='nav-item hide-nav-item'>

              <div className="dropdown">
                <button className="btn btn-light dropdown-toggle bg-transparent" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className='profilebutton'>
                    {initialsName}
                  </span>
                  {profileName}
                </button>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="#">Cerrar sesión</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} aria-current="page" to="proceso" onClick={()=>handleNavbar()}>Proceso</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} aria-current="page" to="evaluacion" onClick={()=>handleNavbar()}>Evaluación BIA</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'} aria-current="page" to="mantenedor" onClick={()=>handleNavbar()}>Mantenedor</NavLink>
            </li>
          </ul>
        </div>
        
        <ul className='navbar-nav me-auto show-nav-item'>
          <li className="nav-item">
            <button className='btn bg-green-light text-white rounded-pills' type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvascreateprocess" aria-controls="#offcanvascreateprocess" >Crear proceso</button>
          </li>
          <li className='nav-item'>

            <div className="dropdown">
              <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className='profilebutton'>
                  GS
                </span>
                Gabriel Sepúlveda
              </button>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="#">Cerrar sesión</Link></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

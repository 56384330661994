import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { StatusFormAccept } from '../statusform/StatusFormAccept';

export const SystemAddForm = (props) => {
    const [editSystem, setEditSystem] = useState(true)
    const [response, setResponse] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);

    const saveSystem = () => {
        setEditSystem(false)
        setResponse(true)
    }

    if(editSystem){
        return (
            <>
                <div className="container">
                    <div className="col-12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="idsystem" className="form-label">ID</label>
                                <input type="text" className="form-control" id="idsystem" placeholder="Código ID de sistema"  {...register("example")} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="areasystem" className="form-label">Sistema</label>
                                <select name="areasystem" id="areasystem" className='form-control'>
                                    <option selected disabled>Seleccione sistema</option>
                                    <option value="soporte">Soporte</option>
                                    <option value="sistema1">Sistema 1</option>
                                    <option value="sistema2">Sistema 2</option>
                                    <option value="sistema3">Sistema 3</option>
                                </select>
                                {/* <input type="email" className="form-control" id="idsystem" placeholder="Código ID de sistema"  {...register("example")}/> */}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="processsystem" className="form-label">Proceso</label>
                                <input type="text" className="form-control" id="processsystem" placeholder="Proceso de sistema"  {...register("example")} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descripcionsistema" className="form-label"  {...register("exampleRequired", { required: true })}>Descripción</label>
                                <textarea className="form-control" id="descripcionsistema" rows="3"></textarea>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-6 text-start">
                                    <Link data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</Link>
                                </div>
                                <div className="col-6 text-end">
                                    <button type="submit" className='btn btn-primary' onClick={saveSystem}>Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }else if (!editSystem){
        if(response){
            return (
                <>
                    <StatusFormAccept typeTab={props.tab} type={props.type} system={"VR TEST"} process={"Desarrollo de Process Critical Intranet"} stateSystemForm={[setEditSystem,setResponse]}/>
                </>
            )
        }
    }
    
}

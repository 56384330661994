import React from 'react'
import { Link } from 'react-router-dom'
import { ProveedorTab } from './tabs/ProveedorTab'
import { SistemaTab } from './tabs/SistemaTab'
import { PersonalCriticoTab } from './tabs/PersonalCriticoTab'
import { useEffect } from 'react'

import './tabmaintainerstyle.css'

export const TabMaintainer = (props) => {
    const [titleOffcanvas, setTitleOffcanvas] = props.titleOffcanvas
    const [tabOffcanvas, setTabOffcanvas] = props.tabOffcanvas
    const [typeOffcanvas, setTypeOffcanvas] = props.typeOffcanvas

    const handleOffcanvas = (title, tab, type) => {
        setTitleOffcanvas(`${title}`)
        setTabOffcanvas(tab)
        setTypeOffcanvas(type)
    }
    useEffect(() => {
        setTitleOffcanvas("Crear sistema")
        setTabOffcanvas("sistema")
        setTypeOffcanvas("crear")
    }, [])

    return (
        <>
            <ul className="nav nav-tabs mt-sm-4">
                <li className="nav-item">
                    <Link className="nav-link active" to="#sistema" data-bs-toggle="tab" onClick={() => handleOffcanvas("Crear sistema", "sistema", "crear")}>Sistema</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="#proveedor" data-bs-toggle="tab" onClick={() => handleOffcanvas("Crear proveedor", "proveedor", "crear")}>Proveedor</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="#pcritico" data-bs-toggle="tab" onClick={() => handleOffcanvas("Crear Personal crítico", "pcritico", "crear")}>Personal crítico</Link>
                </li>
            </ul>


            <div className="tab-content">
                <div className="tab-pane fade show active" id="sistema">
                    <SistemaTab titleObject={[titleOffcanvas, setTitleOffcanvas]} tabOffcanvas={[tabOffcanvas, setTabOffcanvas]} typeOffcanvas={[typeOffcanvas, setTypeOffcanvas]} />
                    <div className="row justify-content-end">
                        <div className="col-lg-2 col-md-2 col-sm-4 ">
                            <button
                                onClick={() => handleOffcanvas("Crear sistema", "sistema", "crear")}
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasrightsistemacrear"
                                aria-controls="offcanvasrightsistemacrear">
                                Crear +
                            </button>
                        </div>
                    </div>

                </div>
                <div className="tab-pane fade" id="proveedor">
                    <ProveedorTab titleObject={[titleOffcanvas, setTitleOffcanvas]} tabOffcanvas={[tabOffcanvas, setTabOffcanvas]} typeOffcanvas={[typeOffcanvas, setTypeOffcanvas]} />
                    <div className="row justify-content-end">
                        <div className="col-lg-2 col-md-2 col-sm-4 ">
                            <button
                            onClick={() => handleOffcanvas("Crear proveedor", "proveedor", "crear")}
                            className="btn btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasrightproveedorcrear"
                            aria-controls="offcanvasrightproveedorcrear">
                                Crear +
                            </button>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="pcritico">
                    <PersonalCriticoTab titleObject={[titleOffcanvas, setTitleOffcanvas]} tabOffcanvas={[tabOffcanvas, setTabOffcanvas]} typeOffcanvas={[typeOffcanvas, setTypeOffcanvas]} />
                    <div className="row justify-content-end">
                        <div className="col-lg-2 col-md-2 col-sm-4 ">
                            <button
                            onClick={() => handleOffcanvas("Crear Personal crítico", "pcritico", "crear")}
                            className="btn btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasrightpcriticocrear"
                            aria-controls="offcanvasrightpcriticocrear">
                                Crear +
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

import { createSlice } from '@reduxjs/toolkit';

export const systemSlice = createSlice({
    name: 'system',
    initialState: [],
    reducers: {
        setSystem: (state, action) => {
            state = action.payload;
        },
        getSystemById: (state, action) => {
            return state.filter(system => system.id === action.payload);
        },
        getAllSystem: (state, action) => {
            return state;
        }
    },
})

export const { setSystem, getSystemById, getAllSystem } = systemSlice.actions;

export default systemSlice.reducer;
import React from 'react'
import './badgestyle.css'
export const BadgeStatusComponent = (props) => {
    if (props.value <= 2) {
        return (
            <span className="badge rounded-pill badge-l">BAJO</span>
        )
    } else if (props.value > 2 && props.value <= 4) {
        return (
            <span className="badge rounded-pill badge-m">MEDIO</span>
        )
    } else if (props.value > 4 && props.value <= 6) {
        return (
            <span className="badge rounded-pill badge-h">ALTO</span>
        )
    }
    return (
        <span className="badge rounded-pill badge-e">EXTREMO</span>
    )
}
